import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {SubscribeComponent} from './components/subscribe/subscribe.component';
import {AboutUsComponent} from './components/info-pages/about-us/about-us.component';
import {ContactUsComponent} from './components/info-pages/contact-us/contact-us.component';
import {HelpAndSupportComponent} from './components/info-pages/help-and-support/help-and-support.component';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {ReadMoreComponent} from './components/info-pages/articles/read-more/read-more.component';
import {ChatContainerComponent} from './components/chat/chat-container/chat-container.component';
import {VirtualQueueComponent} from './components/virtual-queue/virtual-queue.component';
import {ManagementQueueComponent} from './components/management-queue/management-queue.component';
import {QueueGuard} from './guards/queue.guard';
import {ManagementGuard} from './guards/management.guard';
import {WhiteListGuard} from './guards/whitelist.guard';
import {LandingComponent} from './components/landing/landing.component';
import {LoginGuard} from './guards/login.guard';
import {VerifyEmailComponent} from './verify-email/verify-email.component';
import {TermsOfUseComponent} from './components/info-pages/articles/terms-of-use/terms-of-use.component';

const routes: Routes = [
  {path: '', component: LandingComponent},
  {path: 'chat', component: ChatContainerComponent, canActivate: [WhiteListGuard]},
  {path: 'demo', component: ChatContainerComponent},
  {path: 'pre-alpha', component: ChatContainerComponent},
  {path: 'login', component: LoginComponent, canActivate: [LoginGuard]},
  {path: 'register', component: LoginComponent, canActivate: [LoginGuard]},
  {path: 'subscribe', component: SubscribeComponent, canActivate: [WhiteListGuard]},
  {path: 'about', component: AboutUsComponent},
  {path: 'contact', component: ContactUsComponent},
  {path: 'help', component: HelpAndSupportComponent},
  {path: 'welcome', component: WelcomeComponent},
  {path: 'articles/read-more', component: ReadMoreComponent},
  {path: 'articles/terms-of-use', component: TermsOfUseComponent},
  {path: 'queue', component: VirtualQueueComponent, canActivate: [QueueGuard]},
  {path: 'management-queue', component: ManagementQueueComponent, canActivate: [ManagementGuard]},
  {path: 'verify-email', component: VerifyEmailComponent},

  // Under Maintenance
  // {path: '', component: UnderMaintenanceComponent},
  // Redirection rule for trailing slashes
  {
    path: ':path/',
    pathMatch: 'full',
    redirectTo: '/:path'
  },

  // (Optional) Catch-all wildcard route for 404 handling or other purposes.
  // {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
