import {ChangeDetectionStrategy, Component} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';

@Component({
  selector: 'sbz-chat-jump',
  templateUrl: './chat-jump.component.html',
  styleUrl: './chat-jump.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatJumpComponent extends BaseChatComponent {
  goToLink(link: string) {
    const url = `${window.location.origin}/${link}`;
    window.open(url, '_blank');
  }

  checkPopup() {
    console.log('Total User Uploads: ', this.userStateService.totalUserUploads);
    if (this.userStateService.totalUserUploads >= 3) {
      console.log('openReferralModal from chat-jump');
      this.modalService.openReferralModal(4);
      return;
    }
    this.createChatBubbleWIthText('pre-upload-notice', 'I`m ready to start the session!');
  }
}
