import {ChangeDetectionStrategy, Component} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {UserStateService} from '../../../services/user-state.service';

@Component({
  selector: 'sbz-chat-error-modal',
  templateUrl: './chat-error-modal.component.html',
  styleUrl: './chat-error-modal.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatErrorModalComponent {
  private readonly productionsPacksUrl = 'https://productions-packs.hitcraft.ai/';

  messages: {
    [key: number]: { title: string; body: string; ctaLink?: string };
  } = {
    420: {
      title: 'You\'ve Hit Your Limit!',
      body:
        '<p class="text-left">You’ve reached the maximum sketch uploads for your current plan. Upgrade now to keep creating with HitCraft and enjoy:</p>' +
        '<ul class="ps-4 mt-4 text-left">\n' +
        '  <li>- More song uploads</li>\n' +
        '  <li>- FREE productions</li>\n' +
        '  <li>- FREE production downloads</li>\n' +
        '  <li>- Commercial License</li>\n' +
        '</ul>',
      ctaLink: this.productionsPacksUrl,
    },
    421: {
      title: 'You\'ve Hit Your Limit!',
      body:
        '<p class="text-left">You’ve reached the maximum productions for your current plan.<br> Upgrade now to keep creating with HitCraft and enjoy:</p>' +
        '<ul class="ps-4 mt-4 text-left">\n' +
        '  <li>- More song uploads</li>\n' +
        '  <li>- FREE productions</li>\n' +
        '  <li>- FREE production downloads</li>\n' +
        '  <li>- Commercial License</li>\n' +
        '</ul>',
      ctaLink: this.productionsPacksUrl,
    },
    422: {
      title: '',
      body: '',
    },
    423: {
      title: 'Oops! Your voice is amazing, but we need a little more!',
      body: 'It seems your demo only includes vocals or wasn’t recorded with a metronome. To create a full production, we’ll need instrumental elements too. Upload a demo with instruments recorded to a beat, and we’ll handle the rest!',
    },
    424: {
      title: 'Oops! 3/4 Time Not Supported Yet',
      body: 'We’ve identified that your sketch is in 3/4 time, which we don’t support yet. Currently, we only process 4/4 time signatures. Thanks for your understanding!',
    },
    400: {
      title: '',
      body: '',
    },
    500: {
      title: '',
      body: '',
    },
  };

  constructor(
    public modalService: ModalService,
    private userStateService: UserStateService
  ) {
  }

  closeModal() {
    if (this.modalService.chatErrorModal$.getValue() === 423) {
      this.userStateService.startNewChatFromModal$.next(true);
    }
    this.modalService.closeChatErrorModal();
  }

  openCtaLink() {
    const user = this.userStateService.user$.getValue();
    if (!user) return;

    try {
      const message = this.modalService.chatErrorModal$.getValue()!;
      const ctaLink = this.messages[message]?.ctaLink;
      if (ctaLink) {
        const url = new URL(ctaLink);
        url.searchParams.append('user_id', user.email);
        window.open(url.toString(), '_blank');
      }
    } catch (e) {
      console.log('Error finding link');
      const url = new URL(this.productionsPacksUrl);
      url.searchParams.append('user_id', user.email);
      window.open(url.toString(), '_blank');
    }
    this.modalService.closeChatErrorModal();
  }
}
