import {Component} from '@angular/core';

@Component({
  selector: 'sbz-under-maintenance',
  templateUrl: './under-maintenance.component.html',
  styleUrl: './under-maintenance.component.scss'
})
export class UnderMaintenanceComponent {

}
