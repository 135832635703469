import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {VirtualQueueService} from '../../services/virtual-queue.service';
import {UserStateService} from '../../services/user-state.service';
import {ToastService} from '../../services/toast.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {LoadingService} from '../../services/loading.service';
import {ModalService} from '../../services/modal.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-virtual-queue',
  templateUrl: './virtual-queue.component.html',
  styleUrl: './virtual-queue.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VirtualQueueComponent implements OnInit {
  public userQueueNumber: number | undefined = 0;
  public totalQueueNumber: number = 0;
  public url: string = 'https://invite.hitcraft.ai/';

  constructor(
    private clipBoard: Clipboard,
    private virtualQueueService: VirtualQueueService,
    private cdr: ChangeDetectorRef,
    private userStateService: UserStateService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    public modalService: ModalService
  ) {
  }

  copyToClipBoard(input: HTMLInputElement) {
    this.clipBoard.copy(input.value);
    this.toastService.showToast('Link successfully copied!', 'success');
  }

  ngOnInit(): void {
    this.loadingService.toggleLoading(true);
    this.url += `queue/share/${this.userStateService.user$.getValue()?.token}`;
    this.virtualQueueService.getTotalQeueu(this.userStateService.user$.getValue()?.token!).subscribe((res: {
      code: number,
      data: { totalQueue: string },
      success: boolean
    }) => {
      this.totalQueueNumber = parseInt(res.data.totalQueue);
      this.userQueueNumber = this.userStateService.user$.getValue()?.queuePosition;
      this.cdr.detectChanges();
      this.loadingService.toggleLoading(false);
    }, error => {
      this.modalService.openErrorModal('There was an error. Please try again later.', error);
    });
  }
}
