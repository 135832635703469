import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {environment} from '../../../environments/environment.prod';
import {UntilDestroy} from '@ngneat/until-destroy';
import {UserStateService} from '../../services/user-state.service';
import {BehaviorSubject} from 'rxjs';
import {fadeTransition} from '../../animations/fade-in-animation';
import {ModalService} from '../../services/modal.service';
import {MobileDetectionService} from '../../services/mobile-detection.service';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {User} from '../../interfaces/user';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginComponent implements AfterViewInit {
  flowId = environment.descopeFlowId;
  activeStep: number = 1;
  submitForm = {valid: false};
  hideDescope$ = new BehaviorSubject<boolean>(true);
  showThankYou$ = new BehaviorSubject<boolean>(false);
  user$ = this.userStateService.user$;
  userNeedToVerifyEmail$ = this.userStateService.userNeedToVerifyEmail$;
  userNeedToEnterNewPassword$ = new BehaviorSubject<boolean>(false);
  userNeedToRegister$ = this.userStateService.userNeedToRegister$;
  showPasswordPopup: boolean = false;

  // Email and password fields for custom login form
  email: string = '';
  password: string = '';

  registerEmail: string = '';
  registerPassword: string = '';

  forgotPasswordEmail: string = '';
  forgotPasswordNewPassword: string = '';

  loginError: string | null = null;
  registerError: string | null = null;
  resetPasswordError: string | null = null;

  verificationCode: string[] = ['', '', '', '', '', ''];

  activeForm: 'login' | 'register' | 'forgotPassword' = 'login';
  activeSubForm: 'emailCode' | 'verifyCode' | 'resetPassword' = 'emailCode';
  emailForm: FormGroup;
  loginRegisterTitle: 'Login' | 'Register' | 'Reset Password' = 'Login';
  private descopeSocialLoginContainer = '#PN5ZN_viow';

  constructor(private userStateService: UserStateService,
              private mobileDetectionService: MobileDetectionService,
              private modalService: ModalService,
              private cdr: ChangeDetectorRef,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
    this.emailForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]]
    });
    // this.sendMfaCodeToEmail();
  }

  ngAfterViewInit() {
    const referral_id = this.getItemWithExpiration('referral_id');
    const source = this.getItemWithExpiration('source');
    if (referral_id || location.href.includes('/register')) {
      this.showRegisterForm();
    }
  }

  onSuccess(event: CustomEvent) {
    setTimeout(() => {
      // @ts-ignore
      window.dataLayer = window.dataLayer || [];
      // @ts-ignore
      window.dataLayer.push({
        'event': 'login'
      });
      this.userStateService.updateUserFromDescope(event.detail.sessionJwt, event.detail.user.picture);
      this.mobileDetectionService.isMobile$
        .subscribe({
          next: isMobile => this.onReady()
        });
    });
  }

  submitEmail() {
    if (this.emailForm.valid) {
      const email = this.emailForm.get('email')?.value;
      const token = this.user$.getValue()?.token || '';
      console.log('email: ', email);
      console.log('token: ', token);

      this.userStateService.verifyNewEmail(email, token).subscribe(() => {
        console.log('Email updated successfully');

        const user = this.user$.getValue() as User;
        user.email = email;
        this.user$.next(user);

        this.sendMfaCodeToEmail(email);

        this.cdr.detectChanges();
      });
    }
  }

  onError(event: CustomEvent) {
    this.modalService.openErrorModal('An error occurred while retrieving user state. Please refresh the page.', event);
    console.error('ERROR FROM LOG IN FLOW', event.detail);
  }

  onReady() {
    const descope = document.querySelector('section.descope-container')?.querySelector('descope-wc')!;
    const wcRoot = descope?.shadowRoot?.getElementById('root')!;
    const mainContainer: HTMLElement = wcRoot?.querySelector('descope-container')!;
    if (mainContainer) {
      mainContainer.style.width = '100%';
      mainContainer.style.marginLeft = '-1rem';
      mainContainer.style.marginRight = '-1rem';
      mainContainer.style.marginTop = '-1rem';
    }
    const descopeLoginButtonsContainer = mainContainer?.querySelector(this.descopeSocialLoginContainer);

    if (descopeLoginButtonsContainer) {
      this.setLoginButtonsStyle(descopeLoginButtonsContainer);
      this.hideDescope$.next(false);
    }
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      const code = params['code'];
      if (code) {
        this.userStateService.handleOAuthResponse(code);
      }

      const t = params['t'];
      if (t) {
        const email = params['email'];
        localStorage.setItem('t', t);
        localStorage.setItem('email', email);
        this.submitVerificationCode(t, email);
      }
    });
  }

  submitEmailLogin() {
    if (!this.email || !this.password) {
      this.modalService.openErrorModal('Please enter a valid email and password.', undefined);
      return;
    }

    // Call the login function from userStateService or your authentication service
    this.userStateService.loginWithEmail(this.email, this.password).subscribe({
      next: (response: any) => {
        // this.userStateService.updateUserFromResponse(response);
        // this.modalService.openSuccessModal('Logged in successfully!');
      },
      error: (err: any) => {
        this.loginError = err.error.errorDescription;
        console.error('Login Error: ', err);
        this.cdr.detectChanges();
      }
    });
  }

  submitRegisterForm() {
    this.userStateService.registerWithEmail(this.registerEmail, this.registerPassword)
      .subscribe({
        next: (response) => {
          console.log('Registration response: ', response);
          if (response.ok) {
            this.sendMfaCodeToEmail();
            this.userNeedToVerifyEmail$.next(true);
            this.showThankYou$.next(true);
            setTimeout(() => {
              this.showThankYou$.next(false);
              this.router.navigate(['/']);
            }, 3000);
            // Successfully registered, now switch back to login form
            // this.showLoginForm();
            // this.modalService.openSuccessModal('Registration successful! Please log in.');
          }
        },
        error: (err) => {
          console.error('Registration error: ', err);
          this.registerError = err?.error?.errorDescription || 'An error occurred while registering. Please try again.';
          this.cdr.detectChanges();
        }
      });
  }

  sendMfaCodeToEmail(email?: string) {
    this.userStateService.sendMfaCodeToEmail(this.user$.getValue()?.loginId || '', email || this.registerEmail)
      .subscribe({
        next: (response) => {
          console.log('MFA code sent to email: ', response);
          this.userNeedToVerifyEmail$.next(true);
        },
        error: (err) => {
          console.error('Failed to send MFA code: ', err);
          this.modalService.openErrorModal('Failed to send verification code. Please try again.', err);
        }
      });
  }

  submitResetPasswordForm() {
    this.userStateService.updatePassword(this.user$.getValue()?.loginId || '', this.forgotPasswordNewPassword)
      .subscribe({
        next: (response) => {
          console.log('Password updated: ', response);
          this.router.navigate(['/']);
          this.cdr.detectChanges();
          localStorage.removeItem('t');
          // this.modalService.openSuccessModal('Password updated successfully!');
        },
        error: (err) => {
          console.error('Failed to update password: ', err);
          this.resetPasswordError = err?.error?.errorDescription || 'An error occurred while updating password. Please try again.';
          this.cdr.detectChanges();
          localStorage.removeItem('t');
        }
      });


    // this.userStateService.resetPassword(this.forgotPasswordEmail, this.forgotPasswordNewPassword)
    //   .subscribe({
    //     next: (response) => {}),
    //   },
  }

  sendMfaCodeToRegisteredEmail(email: string) {
    this.userStateService.sendMfaCodeToRegisteredEmail(email)
      .subscribe({
        next: (response) => {
          console.log('MFA code sent to email: ', response);
          this.activeSubForm = 'verifyCode';
          this.cdr.detectChanges();
        },
        error: (err) => {
          console.error('Failed to send MFA code: ', err);
          const errorDescription = err?.error?.errorDescription;
          if (errorDescription.includes('User not found')) {
            this.modalService.openErrorModal('User not found. Please register first.', err);
          } else {
            this.modalService.openErrorModal('Failed to send verification code. Please try again.', err);
          }
        }
      });
  }

  showRegisterForm() {
    this.activeForm = 'register';
    this.loginRegisterTitle = 'Register';
    this.cdr.detectChanges();
  }

  showLoginForm() {
    this.activeForm = 'login';
    this.loginRegisterTitle = 'Login';
    this.cdr.detectChanges();
  }

  forgotPassword() {
    this.activeForm = 'forgotPassword';
    this.loginRegisterTitle = 'Reset Password';
    this.cdr.detectChanges();
  }

  submitVerificationCode(tCode?: string, email?: string) {
    const code = this.verificationCode.join('');
    console.log('code: ', code);
    if (code.length === 6 || !!tCode) {
      if (this.activeSubForm !== 'verifyCode' && !tCode) {
        // Call the service to verify the code
        this.userStateService.verifyEmailCode(this.user$.getValue()?.loginId || email || '', code).subscribe({
          next: (response) => {
            this.userNeedToVerifyEmail$.next(false);
            // this.modalService.openSuccessModal('Email verified successfully!');
          },
          error: (error) => {
            this.modalService.openErrorModal('Invalid verification code. Please try again.', error);
          }
        });
      } else {
        // Call the service to verify the password reset code
        // this.userStateService.verifyMfaCode(this.forgotPasswordEmail, tCode || '').subscribe({
        this.userStateService.verifyMagicLink(tCode || '').subscribe({
          next: (response) => {
            this.userNeedToEnterNewPassword$.next(true);
            console.log('Password reset code verified: ', response);
            this.activeForm = 'forgotPassword';
            this.activeSubForm = 'resetPassword';
            console.log('this.activeSubForm: ', this.activeSubForm);

            console.log({
              userNeedToEnterNewPassword: this.userNeedToEnterNewPassword$.getValue(),
              activeSubForm: this.activeSubForm,
              userNeedToVerifyEmail: this.userNeedToVerifyEmail$.getValue(),
            });

            this.cdr.detectChanges();
          },
          error: (error) => {
            this.modalService.openErrorModal('Invalid verification code. Please try again.', error);
          }
        });
      }

    } else {
      this.modalService.openErrorModal('Please enter a valid 6-digit code.', undefined);
    }
  }

  submitForgotPasswordForm() {
    // Call the service to send a password reset email
    this.sendMfaCodeToRegisteredEmail(this.forgotPasswordEmail);
  }

  onCodeInput(index: number, event: any) {
    const value = event.target.value;
    console.log('value: ', value);
    console.log('index: ', index);
    if (/^\d$/.test(value)) {
      this.verificationCode[index] = value;
      console.log('this.verificationCode: ', this.verificationCode);
      // event.target.value = value;
      this.cdr.detectChanges();
      // if (index < 5) {
      //   console.log('this.verificationCode: ', this.verificationCode);
      //   // setTimeout(() => {
      //   //   console.log('focus next: ', `code-input-${index + 1}`);
      //   //   const nextInput = document.getElementById(`code-input-${index + 1}`);
      //   //   if (nextInput) {
      //   //     // nextInput.focus();
      //   //   }
      //   // });
      // }
    } else {
      // Clear the input if the value is not a digit
      this.verificationCode[index] = '';
      this.cdr.detectChanges();
    }
  }

  hasLowercase(password: string): boolean {
    return /[a-z]/.test(password);
  }

  onPaste(event: ClipboardEvent) {
    // Get the pasted content from the clipboard
    const pastedData = event.clipboardData?.getData('text') || '';

    // Extract only digits from the pasted data
    const digits = pastedData.replace(/\D/g, '');

    // Check if we have at least 6 digits
    if (digits.length >= 6) {
      // Fill the verificationCode array with the first 6 digits
      for (let i = 0; i < 6; i++) {
        this.verificationCode[i] = digits[i];
      }

      // Update the input fields and move focus to the last field
      this.cdr.detectChanges();
      setTimeout(() => {
        const lastInput = document.getElementById(`code-input-5`);
        if (lastInput) {
          lastInput.focus();
        }
      }, 100);
    } else {
      // Optionally handle the case where fewer than 6 digits are pasted
      this.modalService.openErrorModal('Please paste a valid 6-digit code.', undefined);
    }
  }

  changeActiveStep(activeStep: number) {
    this.activeStep = activeStep;
    this.cdr.detectChanges();
  }

  submit() {
    this.submitForm = {valid: true};
    setTimeout(() => {
      this.submitForm = {valid: false};
      this.cdr.detectChanges();
    }, 100);
  }

  loginWithFacebook() {
    this.userStateService.loginWithFacebook();
  }

  loginWithGoogle() {
    this.userStateService.loginWithGoogle();
  }

  private getItemWithExpiration(key: string): string | null {
    try {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) return null;

      const item = JSON.parse(itemStr);
      const now = new Date();

      if (now.getTime() > item.expiration) {
        // If the item is expired, remove it and return null
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    } catch (e) {
      localStorage.removeItem(key);
      return null;
    }
  }

  private setLoginButtonsStyle(descopeLoginButtonsContainer: Element) {
    let fontSize = this.mobileDetectionService.isMobile$.getValue() ? 16 : 24.5;

    const loginButtonsCss = `
        width: 100%;
        border-radius: 42px;
        border-color: rgba(37, 41, 49, 0.3);
        font-size: ${fontSize}px;
        font-weight: 500;
        color: #252931;
    `;
    const googleButton: HTMLElement = descopeLoginButtonsContainer.querySelector('descope-button[data-descope-provider="google"]')!;
    googleButton.style.cssText = `${loginButtonsCss} color: #252931;`;

    const facebookButton: HTMLElement = descopeLoginButtonsContainer.querySelector('descope-button[data-descope-provider="facebook"]')!;
    facebookButton.style.cssText = `${loginButtonsCss} color: white; background-color: #4285f4;`;
  }
}
