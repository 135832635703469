import {Component, HostListener} from '@angular/core';
import {ModalService} from '../../../services/modal.service';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {ToastService} from '../../../services/toast.service';

@Component({
  selector: 'sbz-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrl: './error-modal.component.scss',
  animations: [fadeTransition],
})
export class ErrorModalComponent {
  private keyPressTimes: number[] = []; // Store timestamps of 'C' presses

  constructor(public modalService: ModalService,
              private toastService: ToastService) {
  }

  @HostListener('document:keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (event?.key?.toLowerCase() === 'c') {
      const currentTime = new Date().getTime();
      this.keyPressTimes.push(currentTime);

      // Keep only the last 3 key presses
      if (this.keyPressTimes.length > 3) {
        this.keyPressTimes.shift();
      }

      // Check if the 3 'C' presses are within 500ms
      if (
        this.keyPressTimes.length === 3 &&
        this.keyPressTimes[2] - this.keyPressTimes[0] <= 500
      ) {
        this.copyToClipboard();
        this.keyPressTimes = []; // Reset after copying
      }
    }
  }

  private copyToClipboard() {
    if (this.modalService.errorLog !== undefined) {
      const textToCopy = JSON.stringify(this.modalService.errorLog, null, 2); // Format as JSON for readability
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => this.toastService.showToast('Error log copied to clipboard.', 'info'))
        .catch((err) => console.error('Failed to copy error log:', err));
    } else {
      console.warn('Error log is undefined. Nothing to copy.');
    }
  }
}
