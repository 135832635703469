import {AfterViewInit, Component} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {ModalService} from '../../../services/modal.service';
import {environment} from '../../../../environments/environment.prod';
import {UserStateService} from '../../../services/user-state.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {FormControl, Validators} from '@angular/forms';
import {BehaviorSubject} from 'rxjs';
import {Router} from '@angular/router';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-referral-modal',
  templateUrl: './referral-modal.component.html',
  styleUrl: './referral-modal.component.scss',
  animations: [fadeTransition]
})
export class ReferralModalComponent implements AfterViewInit {
  link = {
    clipboard: '',
    whatsapp: '',
    linkedin: '',
    facebook: '',
    twitter: ''
  };
  emailControls = [
    new FormControl('', [Validators.email]),
    new FormControl('', [Validators.email]),
    new FormControl('', [Validators.email])
  ];
  referralModalObject = {
    title: '',
    body: '',
    hideClose: false
  };
  emailError$ = new BehaviorSubject<boolean>(false);
  errorMessage$ = new BehaviorSubject<string>('');
  isLoading$ = new BehaviorSubject<boolean>(false);
  toastMessage$ = new BehaviorSubject<string>('');

  private readonly baseUrl = environment.production
    ? 'https://hitcraft.ai/register/'
    : 'https://hitcraft-dev.session42.xyz/register/';
  private _toastTimeout: any;

  constructor(public modalService: ModalService,
              private userStateService: UserStateService,
              private httpClient: HttpClient,
              private router: Router) {
  }

  ngAfterViewInit() {
    this.userStateService.user$
      .subscribe(user => {
        if (user) {
          this.modalService.isReferralModalVisible$
            .subscribe({
              next: isReferralModalVisible => {
                if (isReferralModalVisible) {
                  this.userStateService.referralId$
                    .subscribe({
                      next: referralId => {
                        if (referralId) {
                          const referral = `?referral_id=${referralId}`;
                          this.link = {
                            clipboard: this.normalizeReferralId(`${this.baseUrl}?referral_id=${encodeURIComponent(referralId)}&source=clipboard`),
                            whatsapp: encodeURIComponent(this.normalizeReferralId(`${this.baseUrl}${referral}&source=whatsapp`)),
                            linkedin: encodeURIComponent(this.normalizeReferralId(`${this.baseUrl}${referral}&source=linkedin`)),
                            facebook: encodeURIComponent(this.normalizeReferralId(`${this.baseUrl}${referral}&source=facebook`)),
                            twitter: encodeURIComponent(this.normalizeReferralId(`${this.baseUrl}${referral}&source=twitter`)),
                          };
                          this.isLoading$.next(false);
                        } else {
                          this.isLoading$.next(true);
                          this.handleUserReferral();
                        }
                      }
                    });

                  this.modalService.referralPopUpType$
                    .subscribe({
                      next: type => {
                        switch (type) {
                          case 1:
                            this.referralModalObject = {
                              title: `Want to produce your songs for FREE?`,
                              body: `Share HitCraft and unlock more FREE demos to produce once your friends sign up!`,
                              hideClose: false
                            };
                            break;
                          case 2:
                            this.referralModalObject = {
                              title: `Uh-oh. You’ve reached the limit of productions for this demo!`,
                              body: `But don’t worry, you can invite your friends and once they sign up you’ll get more demos to produce!`,
                              hideClose: false
                            };
                            break;
                          case 3:
                            this.referralModalObject = {
                              title: `You’ve used your last demo upload!`,
                              body: `Want more? Invite your friends and once they sign up you’ll get more demos to produce!`,
                              hideClose: false
                            };
                            break;
                          case 4:
                            this.referralModalObject = {
                              title: `Oh shoot, you’re out of demos to produce!`,
                              body: `But you don’t have to stop your flow: Share HitCraft with a friend and get more once they sign up!`,
                              hideClose: true
                            };
                            break;
                        }
                      }
                    });
                }
              }
            });
        }
      });
  }

  copyToClipboard(): void {
    const link = this.link.clipboard;
    if (navigator.clipboard && navigator.clipboard.writeText) {
      // Modern Clipboard API
      navigator.clipboard.writeText(link).then(() => {
        this.showToast('Link copied to clipboard!');
        this.userStateService.notifySharePressed('clipboard');
      }).catch(err => {
        console.error('Failed to copy link: ', err);
        this.showToast('Failed to copy link. Please try again.');
      });
    } else {
      // Fallback for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = link;
      textArea.style.position = 'fixed'; // Prevent scrolling to bottom
      textArea.style.left = '-9999px';  // Place offscreen
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          this.showToast('Link copied to clipboard!');
          this.userStateService.notifySharePressed('clipboard');
        } else {
          this.showToast('Failed to copy link. Please try again.');
        }
      } catch (err) {
        console.error('Failed to copy link: ', err);
        this.showToast('Failed to copy link. Please try again.');
      } finally {
        document.body.removeChild(textArea);
      }
    }
  }

  shareToFacebook(): void {
    this.userStateService.notifySharePressed('facebook');
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.link.facebook}`, '_blank');
  }

  shareToTwitter(): void {
    this.userStateService.notifySharePressed('twitter');
    const text: string = encodeURIComponent('Musicians, this one’s for you: #HitCraft - an #AI tool that transforms your demo into professional sounding songs - on the spot. Try it here for free:');
    window.open(`https://twitter.com/intent/tweet?url=${this.link.twitter}&text=${text}`, '_blank');
  }

  shareToWhatsApp(): void {
    this.userStateService.notifySharePressed('whatsapp');
    const text: string = encodeURIComponent('Check out this free AI tool that transforms your demos into professional sounding songs!');
    window.open(`https://wa.me/?text=${text}%20${this.link.whatsapp}`, '_blank');
  }

  shareToLinkedIn(): void {
    this.userStateService.notifySharePressed('linkedin');
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${this.link.linkedin}`, '_blank');
  }

  shareViaEmail() {
    const validation = this.validateEmails();

    if (!validation.isValid) {
      this.emailError$.next(true);
      this.errorMessage$.next(validation.message);
      return;
    }

    this.isLoading$.next(true);
    const url = 'https://sketch-upload.session42.xyz/send-referral-email';
    const referrerId = this.userStateService.user$.getValue()?.email!;

    const validEmails = this.emailControls
      .map(control => control.value?.trim())
      .filter(email => email && email.length > 0);

    const requests = validEmails.map(email => {
      const formData = new FormData();
      formData.append('referrer_id', referrerId);
      formData.append('referee_id', email!);
      return this.httpClient.post(url, formData).toPromise();
    });

    Promise.all(requests)
      .then(() => {
        this.showToast(`Emails sent successfully!`, 5000);
        this.emailControls.forEach(control => control.reset());
        this.emailError$.next(false);
        this.errorMessage$.next('');
        this.modalService.closeReferralModal();
      })
      .catch(error => {
        console.error('Error sending emails:', error);
        this.showToast('Error sending emails. Please try again.', 5000);
      })
      .finally(() => {
        this.isLoading$.next(false);
      });
  }

  goToHomePage() {
    this.router.navigate([''])
      .then(() => {
        this.modalService.closeReferralModal();
      });
  }

  showToast(message: string, timeOut = 3000) {
    const toast = document.getElementById('toast')!;
    if (this._toastTimeout) {
      clearTimeout(this._toastTimeout);
      toast.className = 'toast';
      setTimeout(() => {
        this.toastMessage$.next(message);
        toast.className = 'toast show';
        this._toastTimeout = setTimeout(() => {
          toast.className = 'toast';
        }, timeOut);
      }, 100);
    } else {
      this.toastMessage$.next(message);
      toast.className = 'toast show';
      this._toastTimeout = setTimeout(() => {
        toast.className = 'toast';
      }, timeOut);
    }
  }

  private normalizeReferralId(url: string): string {
    const [baseUrl, query] = url.split('?'); // Split the URL into base and query part
    if (!query) return url; // If no query, return the URL as is

    const params = query.split('&').filter(param =>
      !param.startsWith('referral_id=') && !param.startsWith('source=')
    );

    const referralMatch = query.match(/referral_id=[^&?]*/);
    const sourceMatch = query.match(/source=[^&?]*/);

    const referralParam = referralMatch ? referralMatch[0] : null;
    const sourceParam = sourceMatch ? sourceMatch[0] : null;

    // Reconstruct the URL with only one occurrence of referral_id and source
    const queryParams = [
      referralParam,
      sourceParam,
      ...params
    ].filter(Boolean).join('&'); // Filter out null or undefined values

    return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
  }

  private validateEmails(): { isValid: boolean, message: string } {
    const filledEmails = this.emailControls
      .map(control => control.value?.trim().toLowerCase())
      .filter(email => email && email.length > 0);

    if (filledEmails.length === 0) {
      return {
        isValid: false,
        message: 'Please enter at least one email address'
      };
    }

    const invalidEmails = this.emailControls
      .filter(control => control.value && control.value.trim().length > 0)
      .filter(control => control.invalid);

    if (invalidEmails.length > 0) {
      return {
        isValid: false,
        message: 'Please enter valid email addresses'
      };
    }

    const uniqueEmails = new Set(filledEmails);
    if (uniqueEmails.size !== filledEmails.length) {
      return {
        isValid: false,
        message: 'Please enter different email addresses'
      };
    }

    return {isValid: true, message: ''};
  }

  private startErrorHandle() {
    this.userStateService.sendRegInfoToDb()
      .subscribe({
        next: value => {
          setTimeout(() => {
            this.handleUserReferral();
          }, 2500);
        },
        error: error => {
          console.log('startErrorHandle: ', error);
        }
      });
  }

  private handleUserReferral() {
    this.userStateService.getUserReferralId(this.userStateService.user$.getValue()!.email)
      .subscribe({
        next: (response: any) => {
          this.userStateService.referralId$.next(response.referral_id);
        },
        error: (error: HttpErrorResponse) => {
          if (error.status === 425) {
            this.startErrorHandle();
          } else {
            this.modalService.openErrorModal('An error occurred while retrieving referral links. Please refresh the page.', error);
          }
          console.log(`handleUserReferral: ${error}`);
        },
        complete: () => {
          this.isLoading$.next(false);
        }
      });
  }
}
