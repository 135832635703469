import {ChangeDetectionStrategy, Component, ViewChild} from '@angular/core';
import {BaseChatComponent} from '../base.chat.component';
import {BehaviorSubject, takeUntil} from 'rxjs';
import {ModalService} from '../../../../services/modal.service';
import {ChatService} from '../../../../services/chat.service';
import {AudioService} from '../../../../services/audio.service';
import {UserStateService} from '../../../../services/user-state.service';
import {ScrollService} from '../../../../services/scroll.service';
import {ChatLoadingBarComponent} from '../chat-loading-bar/chat-loading-bar.component';
import {HttpErrorResponse, HttpEvent, HttpEventType} from '@angular/common/http';

@Component({
  selector: 'sbz-start-session',
  templateUrl: './chat-start-session.component.html',
  styleUrl: './chat-start-session.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatStartSessionComponent extends BaseChatComponent {
  fileName$ = new BehaviorSubject<string | undefined>(undefined);
  fullFileName$ = new BehaviorSubject<string | undefined>(undefined);
  isUploadingFile$ = new BehaviorSubject<boolean>(false);
  isAnalyzingFile$ = new BehaviorSubject<boolean>(false);
  fileUploadProgress$ = new BehaviorSubject<number | null>(null);
  errorTitle$ = new BehaviorSubject<string | undefined>(undefined);
  @ViewChild(ChatLoadingBarComponent) loadingBarComponent!: ChatLoadingBarComponent;

  constructor(modalService: ModalService,
              chatService: ChatService,
              audioService: AudioService,
              userStateService: UserStateService,
              scrollService: ScrollService) {
    super(chatService, audioService, userStateService, modalService, scrollService);
  }

  startFileUpload() {
    const userAcceptedEula = localStorage.getItem('userAcceptedEula');
    if (!this.userStateService.hasUserAcceptedEula() && !userAcceptedEula) {
      this.modalService.openEulaModal();
      return;
    }
    this.errorTitle$.next(undefined);
    this.userStateService.startFileUpload()
      .then((file) => {
        this.fullFileName$.next(file.name);
        this.fileName$.next(this.truncateFileName(file.name));
        // this.audioService.createAudioFileFromSketch(URL.createObjectURL(file));
        if (!this.userStateService.user$.getValue()) {
          this.modalService.openErrorModal('You have to be a registered user and you have to be logged in.', undefined);
          return;
        }

        this.isUploadingFile$.next(true);
        this.scrollService.scrollToBottomClicked$.next(true);

        this.userStateService.uploadSketch(file)
          .pipe(takeUntil(this.audioService.getCancelHttpRequest$()))
          .subscribe({
            next: (event: HttpEvent<any>) => {
              if (event.type === HttpEventType.UploadProgress) {
                // Calculate the progress percentage
                const percentDone = Math.round(100 * event.loaded / (event.total ?? 1));
                // Update progress bar or indicator
                this.fileUploadProgress$.next(percentDone);
                if (percentDone === 100) {
                  this.isUploadingFile$.next(false);
                  this.isAnalyzingFile$.next(true);
                }
              } else if (event.type === HttpEventType.Response) {
                // Handle the final response
                const data = event.body;
                if (!data.bpm.toString().includes('.')) {
                  this.audioService.sketchBpm = data.bpm.toFixed(1); // Preserve floating point format
                } else {
                  this.audioService.sketchBpm = data.bpm;
                }
                if (data.sketch_id) {
                  this.audioService.promptSketchId = data.sketch_id;
                  this.loadingBarComponent.finishLoading();
                  setTimeout(() => {
                    this.isAnalyzingFile$.next(false);
                    this.isUploadingFile$.next(false);
                    this.goToNext('prompt-input');
                  }, 3000);
                } else {
                  this.showError('We could not upload your song. Please try again.');
                }
              }
            },
            error: (error: HttpErrorResponse) => {
              if (error.status) {
                switch (error.status) {
                  case 420:
                    // this.modalService.openChatErrorModal({errorNumber: 420});
                    break;
                  case 423:
                    this.modalService.openChatErrorModal({errorNumber: 423});
                    break;
                  case 424:
                    this.modalService.openChatErrorModal({errorNumber: 424});
                    this.showError('Please try uploading a different file.');
                    break;
                  default:
                    this.showError('We could not upload your song. Please try again.');
                    break;
                }
              } else {
                this.showError('We could not upload your song. Please try again.');
              }

              this.fileName$.next(undefined);
              this.fullFileName$.next(undefined);

              this.isAnalyzingFile$.next(false);
              this.isUploadingFile$.next(false);
            }
          });
      })
      .catch((err: any) => {
        this.showError(err);
      });
  }

  protected override afterViewInit() {
    super.afterViewInit();
    if (this.isDemoSite) {
      this.fileName$.next('sketch.mp3');
      this.scrollService.scrollToBottomClicked$.next(true);
      setTimeout(() => {
        this.isUploadingFile$.next(false);
        this.audioService.promptSketchId = this.audioService.promptMockSketchId;
        this.goToNext('prompt-input');
      }, this.fakeCallsTimeout);
    }
  }

  private showError(title: string) {
    this.isUploadingFile$.next(false);
    this.isAnalyzingFile$.next(false);
    this.errorTitle$.next(title);
  }

  private truncateFileName(fileName: string): string {
    const maxLength = 35;

    if (fileName.length > maxLength) {
      const dotIndex = fileName.lastIndexOf('.');
      const extension = fileName.substring(dotIndex);
      const nameWithoutExtension = fileName.substring(0, dotIndex);

      const allowedNameLength = 12 - extension.length;
      const truncatedName = nameWithoutExtension.substring(0, allowedNameLength) + '...';

      return truncatedName + extension;
    } else {
      return fileName;
    }
  }
}
