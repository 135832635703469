import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {AudioService} from '../../../../services/audio.service';

@Component({
  selector: 'sbz-chat-loading-bar',
  templateUrl: './chat-loading-bar.component.html',
  styleUrls: ['./chat-loading-bar.component.scss']
})
export class ChatLoadingBarComponent implements OnInit, OnDestroy {
  @Input() isLoading!: boolean;
  @Input() text!: string;
  @Input() loadingTimeout: number = 3; // in minutes
  @Input() fileUploadProgress$ = new BehaviorSubject<number | null>(null);
  progress$ = new BehaviorSubject<number>(0);
  noPreviewsBug = false;
  private progress = 0;
  private intervalId: any;
  private textIntervalId: any;
  private butcherTaskArnFromBug: string | undefined = undefined;

  private messages = [
    'HitCraft will email your fully produced track shortly, so feel free to close this window and let the magic happen!'
  ];

  constructor(private cdr: ChangeDetectorRef, private audioService: AudioService) {
  }

  get loadingDuration(): number {
    return this.loadingTimeout > 0 ? this.loadingTimeout * 60 * 1000 : 3000;
  }

  ngOnInit() {
    if (this.loadingTimeout > 0) {
      this.startProgress();
    }

    this.audioService.firstDynamicButcherResponseCame$
      .subscribe((firstDynamicButcherResponseCame) => {
        if (firstDynamicButcherResponseCame) {
          this.text = 'Amazing... now you can listen to your previews';
        }
      });

    this.audioService.dynamicButcherResponseBug$
      .subscribe({
        next: dynamicButcherResponseBug => {
          if (dynamicButcherResponseBug) {
            this.noPreviewsBug = true;
            this.butcherTaskArnFromBug = dynamicButcherResponseBug;
            this.cdr.detectChanges();
            this.audioService.dynamicButcherResponseBug$.next(undefined);
          }
        }
      });
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    if (this.textIntervalId) {
      clearInterval(this.textIntervalId);
    }
  }

  finishLoading() {
    if (this.intervalId) {
      clearInterval(this.intervalId); // Stop the current progress update
    }

    const remainingProgress = 100 - this.progress;
    const updateSteps = 3;
    const interval = 900; // 2.7 seconds divided into 3 steps (2.7 / 3 = 0.9 seconds per step)

    let steps = 0;

    const finishInterval = setInterval(() => {
      if (steps < updateSteps) {
        this.progress += remainingProgress / updateSteps;
        this.progress = Math.ceil(this.progress); // Round the progress up to the nearest whole number

        // Ensure progress does not exceed 100
        if (this.progress > 100) {
          this.progress = 100;
        }

        this.progress$.next(this.progress);
        this.cdr.detectChanges();
        steps++;
      } else {
        clearInterval(finishInterval);
        setTimeout(() => {
          this.progress = 100; // Ensure the final value is 100
          this.progress$.next(this.progress);
          this.isLoading = false;
          this.cdr.detectChanges();
        }, 300); // Last 0.3 seconds to reach 100%
      }
    }, interval);
  }

  regeneratePreviews() {
    this.audioService.dynamicButcherResponseBug$.next(undefined);
    this.audioService.getDynamicButcher(this.butcherTaskArnFromBug!);
    this.progress$.next(0);
    this.startProgress();
    this.noPreviewsBug = false;
    this.butcherTaskArnFromBug = undefined;
    this.cdr.detectChanges();
  }

  private startProgress() {
    const interval = this.loadingDuration / 90; // Stop at 90%
    this.intervalId = setInterval(() => {
      if (this.progress < 90) {
        this.progress++;
        this.progress$.next(this.progress);
        this.cdr.detectChanges(); // Ensure Angular detects the change in progress
      } else {
        this.isLoading = false;
        this.cdr.detectChanges();
        clearInterval(this.intervalId);
      }
    }, interval);
  }
}
