import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {UserStateService} from '../services/user-state.service';
import {ModalService} from '../services/modal.service';
import {fadeTransition} from '../animations/fade-in-animation';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss'],
  animations: [fadeTransition],
})
export class VerifyEmailComponent implements OnInit {
  user$ = this.userStateService.user$;
  verificationStatus: 'pending' | 'success' | 'error' = 'pending';
  errorMessage: string = '';  // Store error message for failed verification

  constructor(
    private activatedRoute: ActivatedRoute,
    private userStateService: UserStateService,
    private modalService: ModalService,
    private cdr: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {
    // Get the token from the query params
    this.activatedRoute.queryParams.subscribe(params => {
      const token = params['t'];
      const email = params['email'];

      if (token) {
        this.verifyEmailToken(token, email);
      } else {
        this.verificationStatus = 'error';
        this.errorMessage = 'No verification token found in the URL.';
      }
    });
  }

  verifyEmailToken(token: string, email: string) {
    // if (!this.user$.getValue()?.loginId) {
    //   this.router.navigate(['/login']);
    // }
    this.userStateService.verifyEmailCode(email || '', token).subscribe({
      next: (response) => {
        console.log('email verified successfully');
        this.verificationStatus = 'success';
        this.cdr.detectChanges();
      },
      error: (error) => {
        this.modalService.openErrorModal('Invalid verification code. Please try again.', error);
      }
    });
  }

  navigateToLogin(): void {
    window.location.href = '/login';
  }
}
