@if (currentActiveComponent) {
  <div class="sketch-results-container">
    @for (i of [0]; track i) {
      @if (sketchResults[i]) {
        <div class="flex flex-col gap-2 w-full sm:w-1/2">
          <button class="chat-results-button chat-button-not-selected flex-grow relative"
                  [ngClass]="{'selected-option': currentActiveUrl === sketchResults[i].url}"
                  (click)="loadUrlToPlayer(sketchResults[i], true)">
            {{ getTitle(i) }}
            @if (sketchResults[i].downloadSpinnerIsOn) {
              <i class="fa-2x fas fa-spinner fa-spin absolute top-2 right-4 z-50"></i>
            } @else {
              <i class="fa-solid fa-download absolute top-1/2 transform -translate-y-1/2 right-4 z-50 hover:text-gray-200 transition-all"
                 title="Download song number {{i + 1}}"
                 (click)="downloadSong(sketchResults[i], $event)"></i>
            }
          </button>
        </div>
      } @else {
        @if (!isLoadingFinished) {
          <button disabled class="chat-button-generic animate-pulse flex-grow">Loading option {{ i + 1 }}...</button>
        }
      }
    }
  </div>

  <!-- Player Wrapper -->
  <div [ngClass]="{'animate-pulse pointer-events-none': sketchResults.length === 0}" class="player-wrapper">
    @if (playerLoading$ | async) {
      <div class="h-fit z-50 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bottom-0">
        <div class="flow-loader"></div>
      </div>
    }
    <!-- Waveform Container -->
    <div [ngClass]="{'blur-custom': playerLoading$ | async}" class="waveform-wrapper">
      <div #progressLineFinal class="progress-line"></div>
      <div class="waveform-inner-wrapper">
        <!-- WaveSurfer Waveform -->
        <div #waveformFinal id="waveform"></div>
        <div #progressDivFinal class="progress-div"></div>

        <!-- Play/Pause Button -->
        <button (click)="playPause()">
          <fa-icon
              [icon]="isPlaying ? 'pause' : 'play'"
              class="play-button-class"
          ></fa-icon>
        </button>
      </div>
    </div>
  </div>
} @else {
  <p class="chat-text">Player not available during new genre selection.</p>
  <p class="chat-text">Don't worry - we will email you the results.</p>
}

<div class="flex flex-wrap w-full mt-[14px] px-[22px]">
  <button (click)="selectDifferentGenre()"
          class="continue-button-class chat-results-button chat-button-generic !ml-auto !w-full sm:!w-fit">
    <span class="mx-auto">Try with a different Genre</span>
  </button>
</div>
