import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {fadeTransition} from '../../animations/fade-in-animation';
import {SubscriptionService} from '../../services/subscription.service';
import {StripeService} from '../../services/stripe.service';
import {UserStateService} from '../../services/user-state.service';
import {Router} from '@angular/router';
import {ModalService} from '../../services/modal.service';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MobileDetectionService} from '../../services/mobile-detection.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-subscribe',
  templateUrl: './subscribe.component.html',
  styleUrl: './subscribe.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscribeComponent implements OnInit {
  @Output() selectedPackageId = new EventEmitter<number>();
  goToPayment$ = new BehaviorSubject(false);
  subscriptionPackages$ = this.subscriptionService.subscriptionPackages$;
  existingPackageId$ = new BehaviorSubject<string | undefined>(undefined);
  couponId: string = '';

  @ViewChild('cardNumber') cardNumberRef!: ElementRef;
  @ViewChild('cardExpiry') cardExpiryRef!: ElementRef;
  @ViewChild('cardCvc') cardCvcRef!: ElementRef;

  cardNumber!: any;
  cardExpiry!: any;
  cardCvc!: any;

  cardNumberError?: string;
  cardExpiryError?: string;
  cardCvcError?: string;

  packageId: string = '';

  constructor(private subscriptionService: SubscriptionService,
              private stripeService: StripeService,
              private userStateService: UserStateService,
              private router: Router,
              private modalService: ModalService,
              private mobileDetectionService: MobileDetectionService) {
  }

  ngOnInit() {
    const user = this.userStateService.user$.getValue();
    if (user && user.selectedPackageId !== '') {
      this.existingPackageId$.next(user.selectedPackageId);
    }

    this.subscriptionService.packageCouponId$
      .subscribe((couponId) => {
        this.couponId = (couponId);
      });
  }

  choosePackage(packageId: string, couponId: string = '') {
    const user = this.userStateService.user$.getValue();
    if (user) {
      this.goToPayment$.next(true);
      this.packageId = packageId;
      this.couponId = couponId;
      setTimeout(() => {
        // this.initializeStripe();
      }, 100);
    } else {
      this.router.navigate(['login']).then();
    }
  }

  onSubmit() {
    const fields = ['cardNumber', 'expirationDate', 'cvv'];

    if (!this.areFormStepValid()) {
      return;
    }
    this.stripeService.createPaymentMethod(this.cardNumber, this.packageId, this.couponId)
      .then((result: any) => {
        this.userStateService.updateSubscriptionStatus(this.packageId);
        this.router.navigate(['welcome']).then();
      })
      .catch((error) => {
        this.modalService.openErrorModal('There was an error verifying your payment. Please try again later.', error);
      });
  }

  backToPackages() {
    this.goToPayment$.next(false);
  }

  enterCoupon() {
    this.modalService.openCouponModal();
  }

  private initializeStripe() {
    const lineHeight = this.mobileDetectionService.isMobile$.getValue() ? '45px' : '60px';
    const stripeStyle = {
      base: {
        color: '#252931',
        fontSize: '16px',
        fontWeight: '300',
        letterSpacing: '-0.16px',
        fontFamily: '"Poppins", ui-sans-serif, sans-serif',
        '::placeholder': {
          color: '#25293166'
        },
        border: '1px solid rgba(37, 41, 49, 0.3)',
        borderRadius: '29.7px',
        padding: '0 26px',
        lineHeight
      },
      invalid: {
        color: '#f00',
        iconColor: '#f00'
      },
    };

    this.cardNumber = this.stripeService.createCardNumberElement(stripeStyle);
    this.cardNumber.mount(this.cardNumberRef.nativeElement);
    this.cardNumber.on('change', (event: any) => {
      this.cardNumberError = event.error ? event.error.message : undefined;
    });

    this.cardExpiry = this.stripeService.createCardExpiryElement(stripeStyle);
    this.cardExpiry.mount(this.cardExpiryRef.nativeElement);
    this.cardExpiry.on('change', (event: any) => {
      this.cardExpiryError = event.error ? event.error.message : undefined;
    });

    this.cardCvc = this.stripeService.createCardCvcElement(stripeStyle);
    this.cardCvc.mount(this.cardCvcRef.nativeElement);
    this.cardCvc.on('change', (event: any) => {
      this.cardCvcError = event.error ? event.error.message : undefined;
    });
  }

  private areFormStepValid(): boolean {
    let isCardNumberValid = !this.cardNumberError && this.cardNumber._complete;
    let isCardExpiryValid = !this.cardExpiryError && this.cardExpiry._complete;
    let isCardCvcValid = !this.cardCvcError && this.cardCvc._complete;

    return isCardNumberValid && isCardExpiryValid && isCardCvcValid;
  }
}
