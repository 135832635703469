<div class="min-h-screen bg-[#f4f4f5] flex items-center justify-center p-4">
  <div class="text-center max-w-md mx-auto">
    <div class="float mb-12">
      <svg class="mx-auto" height="120" viewBox="0 0 120 120" width="120">
        <circle cx="60" cy="60" fill="#18181b" r="58"/>
        <g class="blink">
          <ellipse cx="40" cy="60" fill="white" rx="10" ry="12"/>
          <ellipse cx="80" cy="60" fill="white" rx="10" ry="12"/>
        </g>
      </svg>
    </div>

    <h1 class="text-4xl font-bold text-indigo-900 mb-12">HitCraft</h1>

    <div class="card bg-white rounded-2xl p-8 mb-8">
      <h2 class="text-2xl font-semibold text-gray-800 mb-4">We're Fine-tuning Our AI 🎵</h2>
      <p class="text-gray-600 mb-8">
        Taking a quick break to enhance our music generation. We'll be back soon with even better beats!
      </p>

      <div class="inline-flex items-center justify-center bg-indigo-600 text-white rounded-full px-6 py-3">
        <span class="mr-2">Processing</span>
        <span class="flex">
                    <span class="dot"></span>
                    <span class="dot"></span>
                    <span class="dot"></span>
                </span>
      </div>
    </div>

    <p class="text-gray-500">Expected return: Soon™</p>
  </div>
</div>
