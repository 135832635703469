import {AfterViewInit, Component, OnInit} from '@angular/core';
import {initFlowbite} from 'flowbite';
import {SubscriptionService} from './services/subscription.service';
import {MobileDetectionService} from './services/mobile-detection.service';
import {UserStateService} from './services/user-state.service';
import Hotjar from '@hotjar/browser';
import {UntilDestroy} from '@ngneat/until-destroy';
import {ModalService} from './services/modal.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, AfterViewInit {
  private siteId = 5170357;
  private hotjarVersion = 6;

  constructor(private subscriptionService: SubscriptionService,
              private mobileDetectionService: MobileDetectionService,
              private modalService: ModalService,
              private userStateService: UserStateService) {
  }

  ngOnInit() {
    // this.modalService.openReferralModal(4);
    if (window.location.href.includes('referral_id=')) {
      this.extractParams(window.location.href);
    }

    Hotjar.init(this.siteId, this.hotjarVersion);

    initFlowbite();

    this.subscriptionService.fetchSubscriptionPackages().then();

    this.mobileDetectionService.startBreakpointObserver();
  }

  ngAfterViewInit() {
    this.userStateService.changeImage();
  }

  private extractParams(url: string) {
    const urlObj = new URL(url); // Parse the URL
    const params = new URLSearchParams(urlObj.search); // Extract the query string parameters

    const referralId = params.get('referral_id')!; // Get the value of 'referral_id'
    const source = params.get('source')!; // Get the value of 'source'
    this.setItemWithExpiration('referral_id', referralId, 60);
    this.setItemWithExpiration('source', source, 60);
  }

  private setItemWithExpiration(key: string, value: string, expirationInMinutes: number) {
    const now = new Date();
    const item = {
      value: value,
      expiration: now.getTime() + expirationInMinutes * 60 * 1000, // Current time + expiration
    };
    localStorage.setItem(key, JSON.stringify(item));
  }
}
