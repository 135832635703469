@if (modalService.isFinalResultModalVisible$ | async) {
  <div [@fadeTransition] class="modal-background">
    <div class="modal-container final-result-modal">
      <h2 class="modal-header text-center">HitCraft’s Got This!</h2>
      <p class="modal-content">
        Your production will be ready shortly, but you don’t have to wait while HitCraft works its magic.
        Feel free to close this window, and we’ll send the fully produced track straight to your email as soon as it’s
        ready.<br>
        Happy creating!
      </p>
      <button (click)="modalService.closeFinalResultModal()"
              class="main-button-design gradient-button !w-1/2 mt-[37px] mx-auto !text-[18px] !h-[61px]">
        OK
      </button>
    </div>
  </div>
}
