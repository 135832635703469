import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {UserStateService} from '../services/user-state.service';

export const LoginGuard: CanActivateFn = (route, state) => {
  const userStateService = inject(UserStateService);
  const router = inject(Router);

  const user = userStateService.user$.getValue();
  const isTokenValid = !!user?.token;
  const isFinishedRegistration = user?.finishedRegistration;
  const isChatRoute = state.url === '/chat';
  const isLoginRoute = state.url === '/login' || state.url === '/register';

  // Prevent an infinite loop of navigation by checking the target URL
  if (isChatRoute && !isFinishedRegistration) {
    if (state.url !== '/login') {
      router.navigate(['/login']).then();
    }
    console.log('LoginGuard: Access denied - Chat route requires registration');
    return false;
  }

  if (isLoginRoute && isTokenValid && isFinishedRegistration) {
    if (state.url !== '/') {
      router.navigate(['']).then();
    }
    console.log('LoginGuard: Access denied - Already logged in');
    return false;
  }

  if (isTokenValid && !isFinishedRegistration) {
    return true;
  }

  if (!user) {
    return true;
  }

  console.log('LoginGuard: Access denied');
  return false;
};
