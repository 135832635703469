import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment.prod';
import {SubscriptionPackage} from '../interfaces/subscriptionPackage';
import {LoadingService} from './loading.service';
import {ModalService} from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  subscriptionPackages$ =
    new BehaviorSubject<SubscriptionPackage[] | null>(null);
  packageCouponId$ = new BehaviorSubject<string>('');

  constructor(private loadingService: LoadingService,
              private modalService: ModalService) {
  }

  async fetchSubscriptionPackages() {

    try {
      const url = `${environment.baseUrl}/subscriptions/packages`;

      const subscriptionResponse = await fetch(url);
      const data = await subscriptionResponse.json();
      const subscriptionPackages = this.setAndSortPackages(data);
      this.subscriptionPackages$.next(subscriptionPackages);
    } catch (error) {
      this.modalService.openErrorModal('There was an error while fetching subscription packages. Please refresh the page', error);
      console.error('Failed to fetch subscription packages', error);
      this.subscriptionPackages$.next(null);
    } finally {
      this.loadingService.toggleLoading(false);
    }
  }

  setAndSortPackages(data: any): SubscriptionPackage[] {
    // Ensure data is an array, even if it's a single object
    const packagesArray = Array.isArray(data) ? data : [data];

    // Transform the data to match the SubscriptionPackage interface
    const transformed = packagesArray.map(item => {
      const {packageId, userType, amount, currency, creditsDuration, isRecommended, metadata} = item;
      const extraInfo: string[] = metadata.extraInfo?.split(';');
      const extraInfoForProfile: string[] = metadata.extraInfoForProfile?.split(';');

      return {
        packageId,
        userType,
        totalCredits: metadata.credits,
        amount,
        currency,
        creditsDuration,
        isRecommended: metadata.isRecommended,
        order: metadata.order,
        extraInfo,
        recurringInterval: item.recurring.interval,
        extraInfoForProfile
      } as SubscriptionPackage;
    });

    // Sort packages based on the 'order' property
    return transformed.sort((a, b) => a.order - b.order);
  }
}
